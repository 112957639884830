import React from 'react';
import { Link } from 'gatsby';

// import NewsImage01 from '../images/news-01.jpg';
// import NewsImage02 from '../images/news-02.jpg';
// import NewsImage03 from '../images/news-03.jpg';
// import NewsAuthor01 from '../images/news-author-01.jpg';
// import NewsAuthor02 from '../images/news-author-02.jpg';

function News() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1 mb-4 text-center text-4xl font-extrabold leading-tight tracking-tighter">Check out our demos</h1>
          </div>

          {/* Categories */}
          {/* <div className="mb-12 md:mb-16">
            <ul className="flex flex-wrap justify-center text-sm font-medium -m-2">
              <li className="m-2">
                <a className="inline-flex text-center text-gray-100 py-2 px-4 rounded-full bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out" href="#0">Developers</a>
              </li>
              <li className="m-2">
                <a className="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">SaaS</a>
              </li>
              <li className="m-2">
                <a className="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">Web Agencies</a>
              </li>
              <li className="m-2">
                <a className="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">E-Commerce</a>
              </li>
              <li className="m-2">
                <a className="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">Startups</a>
              </li>
            </ul>
          </div> */}

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out">
                <header>
                  <Link to="/demo/ProductDemo" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                      src={"https://i.imgur.com/mG5If0q.gif"} width="352" height="198" alt="Keyboard demo" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <span className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" 
                        >Demo</span>
                      </li>
                      <li className="m-1">
                        <span className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out"
                         href="#0">eCommerce</span>
                      </li>
                    </ul>
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight">
                    <Link to="/demo/ProductDemo" className="hover:underline">eCommerce Demo</Link>
                  </h3>
                </header>

              </article>

              {/* 2nd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
                <header>
                  <Link to="/demo/ProductListingDemo" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 translate-z-0 
                      hover:-translate-y-1 transition duration-700 ease-out" src={"https://i.imgur.com/ehgapw9.gif"} width="352" height="198" alt="News 02" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">
                          Demo</a>
                      </li>
                      <li className="m-1">
                        <span className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition 
                        duration-150 ease-in-out" >eCommerce</span>
                      </li>
                    </ul>
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight">
                    <Link to="/demo/ProductListingDemo" className="hover:underline">Product Listing Demo</Link>
                  </h3>
                </header>

              </article>

              {/* 3rd article */}
              <article className="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
                <header>
                  <Link to="/demo/FullScreenImages" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                      <img className="absolute inset-0 w-full h-full object-cover transform scale-105 translate-z-0 
                      hover:-translate-y-1 transition duration-700 ease-out" src={"https://i.imgur.com/2VSOhr1.gif"} width="352" height="198" 
                      alt="Keyboards demo" />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <span className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" 
                        >Demo</span>
                      </li>
                      <li className="m-1">
                        <span className="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" 
                        >
                          Full Screen
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3 className="text-xl font-bold leading-snug tracking-tight">
                    <Link to="/demo/FullScreenImages" className="hover:underline">Full Screen Example </Link>
                  </h3>
                </header>

              </article>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default News;
