import React, {useEffect, useState} from 'react';
import 'lightbox.js-react/dist/index.css'

import {SlideshowLightbox, Image, Lightbox} from "lightbox.js-react"

function Demo() {
    const cyberpunkImages = [{title: 0, src: "https://source.unsplash.com/1600x1200/?cyberpunk", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/1600x1200/?cyberpunk+night", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/1600x1200/?city+night", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/1600x1200/?cyberpunk+city", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/800x1200/?neon+city", caption: "Neon night"},
    {title: 4, src: "https://source.unsplash.com/800x1200/?neon+city", caption: "Neon night"}];

    const cityImages = [{title: 0, src: "https://source.unsplash.com/pAKCx4y2H6Q/1400x1200", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/HF3X2TWv1-w/1600x1200", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/ce-K_rj1P7U/1600x1200", caption: "Neon night"},
    {title: 5, src: "https://source.unsplash.com/HF3X2TWv1-w/1600x1200", caption: "Neon night"}

  ];

    const lightThemeImages = [{title: 0, src: "https://source.unsplash.com/1600x1200/?desert", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/1600x1200/?purple+desert", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/1600x1200/?blue+sky+clouds", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/1600x1200/?rolling+hills", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/800x1200/?landscape", caption: "Neon night"}, 
    {title: 5, src: "https://source.unsplash.com/800x1200/?green+hills", caption: "Neon night"}];

    const desertImages = [{title: 0, src: "https://source.unsplash.com/C8ikzkkLLso/1400x1200", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/jOF2TFSNxQI/1400x1200", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/RsRTIofe0HE/1600x1200", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/FIKD9t5_5zQ/1600x1200", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/fHu41_uamys/1600x1200", caption: "Neon night"}, 
    {title: 5, src: "https://source.unsplash.com/Qw3w0oBH63s/1600x1200", caption: "Neon night"}];

    const desertImages2 = [{title: 0, src: "https://images.pexels.com/photos/13425910/pexels-photo-13425910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", 
    alt: "Nature landscape with trees"},
    {title: 1, src: "https://images.pexels.com/photos/4275885/pexels-photo-4275885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", 
    alt: "Nature landscape"},
    {title: 2, src: "https://images.pexels.com/photos/580151/pexels-photo-580151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", alt: "Nature lanscape"},
    {title: 3, src: "https://images.pexels.com/photos/11407630/pexels-photo-11407630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", alt: "Mountains"},
    {title: 4, src: "https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", alt: "Mountains "}, 
    {title: 5, src: "https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", alt: "Mountains with sky in background"}];
    
    const [images, setImages] = useState(desertImages2);
    const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    let classNames = document.documentElement.classList;
  });

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 text-3xl lg:text-4xl font-extrabold leading-tight tracking-tighter mb-4">Try it out here </h2>
            <p className="text-xl text-gray-400">To see Lightbox.js in action, simply click any image below.</p>
          </div>
            <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto" showThumbnails={true}
             theme={"lightbox"} images={images} lightboxIdentifier="lightbox41" modalClose="clickOutside"> 
              <img className="w-full rounded object-cover" style={{height: "100%"}} data-lightboxjs="lightbox41" src={images[0].src} data-aos="zoom-y-out" data-aos-delay="50" />
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[1].src} data-lightboxjs="lightbox41" data-aos="zoom-y-out" data-aos-delay="100" />  
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[2].src} data-lightboxjs="lightbox41" data-aos="zoom-y-out" data-aos-delay="150" />
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[3].src} data-lightboxjs="lightbox41" data-aos="zoom-y-out" data-aos-delay="200" />       
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[4].src} data-lightboxjs="lightbox41" data-aos="zoom-y-out" data-aos-delay="250" />
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[5].src} data-lightboxjs="lightbox41" data-aos="zoom-y-out" data-aos-delay="300" />   
                     
            </SlideshowLightbox>


        </div>
      </div>
    </section>
  );
}

export default Demo;
