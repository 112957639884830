import React from 'react';
import {Link} from "gatsby";

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 font-inter mt-24">
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="bg-blue-600 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out">

            <div className="flex flex-col lg:flex-row justify-between items-center">

              {/* CTA content */}
              <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
                <h3 className="h3 text-white mb-2 text-3xl font-extrabold">Ready to get started?</h3>
                <p className="text-white text-lg opacity-75">Get setup quickly and easily with our quick-start guide.</p>
              </div>

              {/* CTA button */}
              <div>
                <Link className="btn text-blue-600 bg-gradient-to-r from-blue-100 to-white 
                font-medium w-full inline-flex items-center justify-center 
                border border-transparent px-4 py-2 my-2 rounded-sm text-blue-500 bg-purple-600 hover:bg-purple-700 transition duration-150 
                ease-in-out" to="/docs">View Docs</Link>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Cta;
