import React, {useEffect, useState} from 'react';
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, Image, Lightbox} from "lightbox.js-react"

function DarkThemeDemo() {
    const cyberpunkImages = [{title: 0, src: "https://source.unsplash.com/1600x1200/?cyberpunk", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/1600x1200/?cyberpunk+night", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/1600x1200/?city+night", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/1600x1200/?cyberpunk+city", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/800x1200/?neon+city", caption: "Neon night"},
    {title: 4, src: "https://source.unsplash.com/800x1200/?neon+city", caption: "Neon night"}];

    const cityImages = [{title: 0, src: "https://source.unsplash.com/pAKCx4y2H6Q/1400x1200", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/HF3X2TWv1-w/1600x1200", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/ce-K_rj1P7U/1600x1200", caption: "Neon night"},
    {title: 5, src: "https://source.unsplash.com/HF3X2TWv1-w/1600x1200", caption: "Neon night"}

  ];

  const cityImages2 = [{title: 0, src: "https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Street at night"},
{title: 1, src: "https://images.pexels.com/photos/2693282/pexels-photo-2693282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Cyberpunk night"},
{title: 2, src: "https://images.pexels.com/photos/315191/pexels-photo-315191.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "City night"},
{title: 3, src: "https://images.pexels.com/photos/2303337/pexels-photo-2303337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon city"},
{title: 4, src: "https://images.pexels.com/photos/2339009/pexels-photo-2339009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon night"},
{title: 5, src: "https://images.pexels.com/photos/2434627/pexels-photo-2434627.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon night"}

];

    const lightThemeImages = [{title: 0, src: "https://source.unsplash.com/1600x1200/?desert", caption: "Street at night"},
    {title: 1, src: "https://source.unsplash.com/1600x1200/?purple+desert", caption: "Cyberpunk night"},
    {title: 2, src: "https://source.unsplash.com/1600x1200/?blue+sky+clouds", caption: "City night"},
    {title: 3, src: "https://source.unsplash.com/1600x1200/?rolling+hills", caption: "Neon city"},
    {title: 4, src: "https://source.unsplash.com/1600x1200/?landscape", caption: "Neon night"}, 
    {title: 5, src: "https://source.unsplash.com/1600x1200/?green+hills", caption: "Neon night"}];
    
    const [images, setImages] = useState(cityImages2);
    const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    let classNames = document.documentElement.classList;

    // console.log("classes demo ", classNames);
    // if (classNames.contains("dark")) {
    //   setIsDarkTheme(true);
    // }
    // else {
    //   setIsDarkTheme(false);
    //   setImages(lightThemeImages)
    // }

  })

  return (
    <section className="bg-gray-900">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 text-3xl text-gray-100 lg:text-4xl font-extrabold leading-tight tracking-tighter mb-4">Dark theme demo </h2>
            <p className="text-xl text-gray-400">To see Lightbox.js in action, simply click on one of the images in the gallery
            below and the lightbox will display.</p>
          </div>
            <SlideshowLightbox className="container grid grid-cols-3 gap-4 mx-auto" showThumbnails={true}
              modalClose="clickOutside"
             theme={"night"}> 
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[0].src} data-aos="zoom-y-out" data-aos-delay="50"/>
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[1].src} data-aos="zoom-y-out" data-aos-delay="100"/>  
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[2].src} data-aos="zoom-y-out" data-aos-delay="150"/>
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[3].src} data-aos="zoom-y-out" data-aos-delay="200"/>       
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[4].src} data-aos="zoom-y-out" data-aos-delay="250" />
              <img className="w-full rounded object-cover" style={{height: "100%"}} src={images[5].src} data-aos="zoom-y-out" data-aos-delay="300"/>   
                     
            </SlideshowLightbox>

          {/* <SlideshowAnim>
            <img  src={"https://i.imgur.com/YdQhdzi.png"} alt="News 11" />
        </SlideshowAnim> */}
          {/* Articles */}

        </div>
      </div>
    </section>
  );
}

export default DarkThemeDemo;
